import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import authReducer from './auth/AuthReducer';
import networkReducer from './network-status/NetworkReducer';
import snackbarReducer from './snackbar/snackbarReducer';
import templateReducer from './template/TemplateReducer';
import activityReducer from './action-activities/ActivityReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  authReducer,
  networkReducer,
  snackbarReducer,
  templateReducer,
  activityReducer
});

export default RootReducers;
