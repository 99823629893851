// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const CRIMSON_THEME = 'CRIMSON_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const SUREWORX_THEME = 'SUREWORX_THEME';
export const SET_LANGUAGE = 'SET_LANGUAGE';

// CONTAINER MODE
export const CONTAINER_LAYOUT = 'CONTAINER_BOXED';

// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// NOTES CONSTANTS
export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';

// AUTH
export const USER_LOGIN = 'USER_LOGIN';
export const USER_SIGNUP = 'USER_SIGNUP';
export const AUTH_STATE = 'AUTH_STATE';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const UPDATE_TENANT = 'UPDATE_TENANT';
export const USER_DETAILS = 'USER_DETAILS';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';

// LOADING
export const TOGGLE_LOADING = 'TOGGLE_LOADING';

// ERROR
export const ERROR = 'ERROR';

// NETWORK_STATUS
export const NETWORK_STATUS = 'NETWORK_STATUS';

// SNACKBAR
export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';

// users
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const ENABLE_USER = 'ENABLE_USER';
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE';
export const DISABLE_USER = 'DISABLE_USER';
export const DISABLE_USER_FAILURE = 'DISABLE_USER_FAILURE';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const ASSIGNED_PROJECTS = 'ASSIGNED_PROJECTS';
export const ASSIGNED_INDUCTIONS = 'ASSIGNED_INDUCTIONS';
export const ASSIGNED_VACCINES = 'ASSIGNED_VACCINES';

// ATTACHMENT VIEWER
export const ATTACHMENT_VIEWER_HANDLER = 'ATTACHMENT_VIEWER_HANDLER';

// TEMPLATES
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';
// Action-Activity
export const GET_ALL_ACTIVIIES = "GET_ALL_ACTIVIIES"
export const SET_ACTION_DETAILS = "SET_ACTION_DETAILS"
export const ADD_ACTIVITY = "ADD_ACTIVITY"
export const SET_ACTION_ASSIGNED_USERS = "SET_ACTION_ASSIGNED_USERS"
export const ADD_TO_AUDIT_HISTORY = "ADD_TO_AUDIT_HISTORY"
export const SET_ALL_AUDIT_HISTORY = "SET_ALL_AUDIT_HISTORY"

// TENANT
export const TENANT_CONFIG = "TENANT_CONFIG"

